<template>
    <section>
        <div class="ml-3 d-flex bg-white br-t-8" style="width:calc(100vw - 506px);height: calc(100vh - 95px);">
            <cargando v-if="loading" />
            <div class="col px-0 border-right">
                <div class="row mx-0 align-items-center border-bottom py-2">
                    <div class="col-auto text-general f-18">
                        Detalle de la promoción
                    </div>
                    <el-popover placement="bottom" effect="light" popper-class="br-8 p-2" class="ml-auto mr-2">
                        <div class="row mx-0 item f-12 p-2" @click="irEditarPromo()">
                            Editar
                        </div>
                        <div class="row mx-0 item f-12 p-2" @click="transladarCarpeta()">
                            Trasladar de carpeta
                        </div>
                        <div class="row mx-0 item f-12 p-2" @click="eliminarPromocion">
                            Eliminar
                        </div>
                        <div slot="reference" class="btn-action border">
                            <i class="icon-dots-vertical f-18" />
                        </div>
                    </el-popover>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100% - 49px)">
                    <div class="row mx-0 mt-3">
                        <div class="col-12">
                            <img :src="infoPromo.imagen" class="obj-cover br-4" width="100%" />
                        </div>
                    </div>
                    <div class="row mx-0 mt-3">
                        <div class="col-12">
                            <p class="f-20 text-general f-600">
                                {{ infoPromo.nombre }}
                            </p>
                            <p class="f-16 mt-3 text-general f-600">
                                Condiciones
                            </p>
                            <p class="f-14 text-general mt-2">
                                {{ infoPromo.condiciones }}
                            </p>
                            <div class="row mx-0 mt-1">
                                <div>
                                    <p class="f-16 mt-3 text-general f-600">
                                        Hora inicio
                                    </p>
                                    <p class="f-14 text-general mt-2">
                                        {{ infoPromo.hora_inicio }}
                                    </p>
                                </div>
                                <div class="mx-4">
                                    <p class="f-16 mt-3 text-general f-600">
                                        Hora fin
                                    </p>
                                    <p class="f-14 text-general mt-2">
                                        {{ infoPromo.hora_fin }}
                                    </p>
                                </div>
                            </div>
                            <p class="f-16 mt-3 text-general">
                                <span class="f-600">Acumulable:</span>
                                <span v-if="infoPromo.acumulable == 1"> Si</span>
                                <span v-else> No</span>
                            </p>
                            <p v-if="infoPromo.tipo == 3 || infoPromo.tipo == 6 && infoPromo.descuento != 0" class="f-16 mt-3 text-general mt-2">
                                <span class="f-600">Porcentaje de descuento:</span>
                                {{ infoPromo.descuento }} %
                            </p>
                            <p class="f-16 mt-3 text-general">
                                <span class="f-600">Texto de etiqueta:</span>
                                {{ infoPromo.texto }}
                            </p>
                            <p class="f-16 mt-3 text-general">
                                <span class="f-600">Productos aplicados</span>
                            </p>
                            <div v-if="infoPromo.categorias == 1" class="row mx-0 pt-2">
                                <div class="mn-pill px-2 mr-3 mb-2 bg-fondo text-general f-14 border">
                                    Cualquier categoria ({{ separadorNumeroDecimales(infoPromo.cant_productos, decimales = 0, simbolo=false) }})
                                </div>
                            </div>
                            <div v-else class="row mx-0 pt-2">
                                <div v-for="(data, index) in infoPromo.categoriasPromo" :key="`cat-${index}`" class="mn-pill px-2 mr-3 mb-2 bg-fondo text-general f-14 border">
                                    {{ data.nombre }} ({{ data.cant_productos }})
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="infoPromo.tipo === 6">
                        <div class="row mx-0 mt-5">
                            <p class="col-12 f-20 text-general f-600 f-16">
                                Días de aplicación:
                            </p>
                        </div>
                        <div v-if="infoPromo.dias_semana != 0" class="row mx-0 my-2 px-3">
                            <div v-for="(dy, d) in infoPromo.dias_semana" :key="`key-dias${d}`" :class="`container-dia${dy == 1 ? '-activo' : ''} mr-2 mb-2 cr-pointer`">
                                {{ textoDias(d) }}
                            </div>
                        </div>
                        <div v-else class="row mx-0">
                            <div v-for="(rp, r) in infoPromo.dias_promo" :key="r" class="col-12 f-16 text-general mb-2">
                                {{ formatearFecha(rp.fecha, 'ddd DD MMM yyyy') }}
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 py-3">
                        <div class="col-10">
                            <card-promocion full-width :borde="infoPromo.borde" :texto="infoPromo.texto" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0">
                <div class="row mx-0 align-items-center border-bottom py-2">
                    <div class="col-auto text-general f-18">
                        Cedis aplicados
                    </div>
                    <div v-if="infoPromo.tipo == 6" class="btn-red ml-auto mr-2" @click="anadirACedis">
                        <i class="icon-plus f-17" />
                    </div>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100% - 49px)">
                    <div v-for="(data, idx) in infoPromo.cedis" :key="`prod-${idx}`" class="row mx-0 border-bottom py-2 px-2">
                        <img :src="data.imagen" class="br-4 obj-cover" width="86" height="86" />
                        <div class="col">
                            <p class="f-16 text-general">
                                {{ data.nombre }}
                            </p>
                            <div v-if="infoPromo.tipo == 6" class="row mx-0 align-items-center">
                                <span v-if="data.estado == true" class="text-general f-16 mx-2">
                                    Activa
                                </span>
                                <span v-else class="text-general f-16 mx-2">
                                    Inactiva
                                </span>
                                <el-switch v-model="data.estado" inactive-color="#637381" active-color="#29D884" @change="cambiarEstadoPromo(data.id)" />
                                <i class="icon-delete-trash f-17 text-general cr-pointer ml-auto" @click="eliminarCedisPromocionModal(data.id)" />
                            </div>
                            <div v-else class="row mx-0 align-items-center">
                                <i class="icon-calendar f-16 text-general" />
                                <span class="text-general f-16 mx-2">
                                    {{ formatearFecha(data.inicio, 'DD MMM') }} - {{ formatearFecha(data.fin, 'DD MMM') }}
                                </span>
                                <span class="f-17 cr-pointer ml-auto" :class="`text-${colorCedis(data.estado_promo)}`">
                                    {{ textoCedis(data.estado_promo) }}
                                </span>
                            </div>
                            <div class="row mx-0 mt-2">
                                <div class="mn-pill bg-fondo text-general">
                                    <i class="icon-product f-15" />
                                    <span class="mx-2">
                                        {{ data.cant_productos }}
                                    </span>
                                </div>
                                <div class="mn-pill bg-general3 text-white">
                                    <i class="icon-cash f-12 mr-2" />
                                    <span class="f-17"> {{ separadorNumeroDecimales(data.cantidad_vendido) }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarPromocion" titulo="Eliminar promocion" mensaje="¿Desea eliminar esta promocion?" @eliminar="eliminarPromo" />
        <modal-eliminar ref="modalEliminarCedis" titulo="Eliminar Cedis" mensaje="¿Desea eliminar este cedis de la promoción?" @eliminar="eliminarCedisPromo" />
        <modal-productos-incluidos ref="modalProductosIncluidos" />
        <modal-anadir-cedis ref="modalAnadirCedis" @actualizado="getInfoPromocion" />
        <modal-trasladar-carpeta ref="modalTrasladarCarpeta" />
    </section>
</template>

<script>
import Carpetas from '~/services/promociones/promociones_carpetas_admin'
export default {
    components: {
        cardPromocion: () => import('./cardPromocion'),
        modalProductosIncluidos: () => import('../partials/modalProductosIncluidos'),
        modalAnadirCedis: () => import('../partials/modalAnadirCedis'),
        modalTrasladarCarpeta: () => import('../partials/modalTrasladarCarpeta')
    },
    data(){
        return {

            id_grupo: this.$route.params.idGrupo,
            infoPromo: {},
            id_promocion_eliminar: null,
            nuevoCedis: null,
            id_nueva_carpeta: null,
            carpetasPromos: [],
            cedisDisponibles: [],
            cedisAgregar: [],
            componentName: 'listarPromociones',
            loading:false
        }
    },
    computed:{
        id_promocion(){
            return Number(this.$route.params.idPromocion)
        }
    },
    watch:{
        id_promocion(){
            this.getInfoPromocion()
        }
    },
    mounted(){
        this.getInfoPromocion()
    },
    methods: {
        async getInfoPromocion(){
            try {
                this.loading = true
                const { data } = await Carpetas.getDetallePromo(this.id_promocion)
                this.infoPromo = data.infoPromo
                this.$emit('promoSeleccionada', this.id_promocion)
            } catch (error){
                return error_catch(error)
            } finally {
                this.loading = false
            }
        },
        productosIncluidos(){
            this.$refs.modalProductosIncluidos.toggle();
        },
        eliminarPromocion(){
            this.$refs.modalEliminarPromocion.toggle();
        },
        eliminarCedisPromocionModal(item){
            this.id_promocion_eliminar = item;
            this.$refs.modalEliminarCedis.toggle();
        },
        transladarCarpeta(){
            this.$refs.modalTrasladarCarpeta.toggle();
        },
        anadirACedis(){
            this.$refs.modalAnadirCedis.toggle();
        },
        textoDias(state){
            switch (state){
            case 0:
                return 'Lun.';
            case 1:
                return 'Mar.';
            case 2:
                return 'Mie.';
            case 3:
                return 'Juev.';
            case 4:
                return 'Vie.';
            case 5:
                return 'Sáb';
            case 6:
                return 'Dom.';
            }
        },
        textoCedis(state){
            switch (state){
            case 1:
                return 'Futura';
            case 2:
                return 'Activa';
            case 3:
                return 'Vencida';
            case 4:
                return 'Inactiva';
            }
        },
        colorCedis(state){
            switch (state){
            case 1:
                return 'general';
            case 2:
                return 'green';
            case 3:
                return 'danger';
            case 4:
                return 'gris';
            }
        },
        async listarCarpetasPromociones(){
            try {
                const { data } = await Carpetas.getListaCarpetasPromociones();
                this.carpetasPromos = data.listaCarpetas;
                this.index = this.carpetasPromos.findIndex(x => x.id == this.id_grupo)
                this.carpetasPromos.splice(this.index, 1)
            } catch (error){
                this.error_catch(error)
            }
        },
        async trasladarPromocion(){
            try {

                const { data } = await Carpetas.trasladarPromoDeCarpeta(this.id_promocion, this.id_nueva_carpeta)
                this.$refs.modalTrasladarCarpeta.toggle();
                this.$router.push({name: 'admin.promociones.grupos'})
                this.notificacion('Éxito', data.mensaje, 'success')
            } catch (error){
                return this.error_catch(error)
            }
        },
        async eliminarPromo(){
            try {
                const { data } = await Carpetas.eliminarPromocion(this.id_promocion)
                this.$router.push({name: 'admin.promociones.grupos.ver'})
                this.$emit('actualizado')
                this.notificacion('Éxito', data.mensaje, 'success')
                this.$refs.modalEliminarPromocion.toggle();
            } catch (error){
                return this.error_catch(error)
            }
        },
        async cambiarEstadoPromo(item){
            const { data } = await Carpetas.cambiarEstadoCedisPromocion(this.id_promocion, item)
            this.notificacion('Éxito', data.mensaje, 'success')
        },
        async eliminarCedisPromo(){
            try {
                const { data } = await Carpetas.eliminarCedisDePromocion(this.id_promocion, this.id_promocion_eliminar)
                this.$emit('actualizado')
                this.getInfoPromocion()
                this.$refs.modalEliminarCedis.toggle();
                this.notificacion('Éxito', data.mensaje, 'success')
            } catch (error){
                return this.error_catch(error)
            }
        },
        irEditarPromo(){
            this.$router.push({name:'admin.promociones.editar', params:{idGrupo:this.id_grupo, idPromocion:this.id_promocion}})
        },
    }
}
</script>
<style lang="scss" scoped>
.container-dia{
    width: 50px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #DFE4E8;
    background-color: #F6F9FB;
    color: #637381;
    display: flex;
    align-items: center;
    justify-content: center;
    &-activo{
        @extend .container-dia;
        background-color: #000000 !important;
        color: #FFFFFF !important;
    }
}
.item{
    cursor: pointer;
    &:hover{ background-color: #F6F9FB; }
}
</style>
